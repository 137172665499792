import { CriteoEventConfig } from 'features/criteo/connector';
import { useCriteoViewEvent } from 'features/criteo/queries';
import { useCriteoTracking } from '../useCriteoTracking';

export type CriteoConfig = {
  enabled?: boolean;
  flagship?: {
    enabled?: boolean;
  };
  sponsoredProducts?: {
    enabled?: boolean;
  };
};

const useCriteoRetailMedia = (eventConfig: CriteoEventConfig, config: CriteoConfig = {}) => {
  const { userHasSufficientConsent } = useCriteoTracking();

  const { flagship: flagshipConfig, sponsoredProducts: sponsoredProductsConfig } = config;
  const flagshipEnabled = flagshipConfig?.enabled ?? true;
  const sponsoredProductsEnabled = sponsoredProductsConfig?.enabled ?? true;
  const enabled = (sponsoredProductsEnabled || flagshipEnabled) && userHasSufficientConsent;

  const { data: criteoResult } = useCriteoViewEvent(eventConfig, enabled);

  const sponsoredProductsPlacements = sponsoredProductsEnabled ? criteoResult?.sponsoredProductsPlacements : [];
  const flagshipPlacements = flagshipEnabled ? criteoResult?.flagshipPlacements : [];

  return {
    flagshipPlacements,
    sponsoredProductsPlacements,
  };
};
export default useCriteoRetailMedia;
