import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { zIndex } from 'theme';
import { breakpointUp } from 'theme/functions';

interface StyledContentfulModelMapperProps {
  backgroundImageUrl?: string;
  withThemedBackground?: boolean;
}

export const StyledContentfulModelMapper = styled.div<StyledContentfulModelMapperProps>`
  ${({ backgroundImageUrl, withThemedBackground }) =>
    withThemedBackground &&
    css<StyledContentfulModelMapperProps>`
      position: relative;

      &:after {
        content: '';
        position: absolute;
        inset: -70% calc(100% - 100vw);
        z-index: ${zIndex('negative')};
        background-repeat: repeat-x;
        background-size: cover;

        ${breakpointUp(
          BREAKPOINTS.LG,
          css`
            background-size: contain;
          `,
        )}

        background-position: center;
        background-image: url(${backgroundImageUrl});
      }
    `}
`;
