import { ObjectPosition } from 'constants/styling';
import { IBlockImage } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { cn } from 'utils/cn';
import { mapResponsiveContentfulImages } from 'utils/contentfulUtil';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';

type ContentfulImageBlockResponsiveProps = {
  className?: string;
  mediumImage?: IBlockImage;
  mobileImage?: IBlockImage;
  objectPosition?: ObjectPosition;
  priority?: boolean;
  sizes?: ImageSizes;
  wideImage?: IBlockImage;
};

const ContentfulImageBlockResponsive = ({
  className,
  mediumImage,
  mobileImage,
  objectPosition,
  priority,
  sizes = { [DEFAULT_SIZE_KEY]: 600 },
  wideImage,
  ...props
}: ContentfulImageBlockResponsiveProps) => {
  if (!(mobileImage || mediumImage || wideImage)) {
    return null;
  }

  const images = mapResponsiveContentfulImages(mobileImage, mediumImage, wideImage);

  return (
    <div>
      {images?.map(
        ({ image, size }) =>
          image && (
            <ContentfulImageBlock
              key={`${image?.fields?.image?.fields?.title}-${size}`}
              className={cn(
                size === 'small' ? 'md:hidden' : '',
                size === 'medium' ? 'hidden md:block lg:hidden' : '',
                size === 'wide' ? 'hidden lg:block ' : '',
                className,
              )}
              content={image}
              hasRelativeParent
              priority={priority}
              sizes={sizes}
              objectPosition={objectPosition}
              {...props}
            />
          ),
      )}
    </div>
  );
};

export default ContentfulImageBlockResponsive;
