import { color, variable } from 'theme';
import styled from 'styled-components';
import Typography from '../../../Typography/Typography';

const appendedSectionWidth = 70;

export const StyledLabel = styled(Typography)`
  position: relative;
`;

export const StyledTimerLabel = styled(Typography)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

interface ColoredBlockProps {
  backgroundColor?: string;
}

export const ColoredBlock = styled.div<ColoredBlockProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || color('primary-300')};
  position: absolute;
  top: 0px;
  right: 0px;
  width: 80%;
  height: 100%;
  border-bottom-right-radius: ${variable('border-radius')};

  &::after {
    content: '';
    position: absolute;
    display: block;
    background-color: ${({ backgroundColor }) => backgroundColor || color('primary-300')};
    height: 100%;
    top: 0;
    width: ${2 * appendedSectionWidth}px;
    right: calc(100% - ${1 * appendedSectionWidth}px);
    border-top-left-radius: ${appendedSectionWidth}px;
    transform-origin: top left;
    transform: skew(-15deg, 0deg);
  }
`;
