import { ContentfulBuildVariable } from 'types/Contentful';
import config from '../config';
import { BRAND_VALUES } from '../constants/brand';
import { log } from './loggerUtil';

const FALLBACK_BRAND = BRAND_VALUES.KREFEL;

const getBrandVariable = () => {
  const brand = process.env.NEXT_PUBLIC_PLATFORM || config.platform;

  if (!brand) {
    log(
      'getBrandVariable - ContentfulBuildVariablesUtil',
      'The brand variable has not been set correctly, fallback brand has been used instead'
    );

    return FALLBACK_BRAND;
  }
  const firstLetter = brand.charAt(0);
  return `${firstLetter.toUpperCase()}${brand.slice(1)}`;
};

const getBuildVariables = (): ContentfulBuildVariable => ({
  brand: getBrandVariable(),
});

export default getBuildVariables;
