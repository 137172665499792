import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import { breakpointUp, spacer } from '../../../theme/functions';
import SocialSharing from '../../SocialSharing/SocialSharing';
import Timestamp from '../../Timestamp/Timestamp';
import ContentfulBannerCountdown from '../ContentfulFields/ContentfulBannerCountdown/ContentfulBannerCountdown';
import ContentfulBlockCountdown from '../ContentfulFields/ContentfulBlockCountdown/ContentfulBlockCountdown';

const MOBILE_SPACING = '50px';
const DESKTOP_SPACING = '2rem';

export const StyledSocialSharing = styled(SocialSharing)`
  margin-left: auto;
`;

export const StyledTimestamp = styled(Timestamp)`
  margin-bottom: ${spacer(125)};
`;

export const BannerWrapper = styled.div`
  position: relative;
`;

export const CountdownDesktop = styled(ContentfulBannerCountdown)`
  display: none;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 70%;
      width: 30%;
    `,
  )}
`;

export const CountdownMobile = styled(ContentfulBlockCountdown)`
  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `,
  )}
`;

interface ContentfulContentWrapperProps {
  hasBanner?: boolean;
  isDefaultTemplate?: boolean;
  template?: string;
}

export const ContentfulContentWrapper = styled.div<ContentfulContentWrapperProps>`
  gap: ${spacer(100)};
  display: flex;
  flex-direction: column;

  margin-top: ${({ hasBanner, template }) => (hasBanner || template === 'body') && spacer(75)};

  ${({ isDefaultTemplate }) =>
    isDefaultTemplate &&
    css`
      gap: ${MOBILE_SPACING};

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          gap: ${DESKTOP_SPACING};
        `,
      )};

      .contentful-template-banner {
        min-height: 350px;

        ${breakpointUp(
          BREAKPOINTS.LG,
          css`
            min-height: 400px;
          `,
        )};
      }

      .contentful-template-cta-bar {
        margin-top: calc(-${MOBILE_SPACING} + ${spacer(125)});

        ${breakpointUp(
          BREAKPOINTS.MD,
          css`
            margin-top: calc(-${DESKTOP_SPACING} + ${spacer(200)});
          `,
        )};
      }
    `}
`;
