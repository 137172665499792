import { BreadcrumbProps } from 'components/Breadcrumb/Breadcrumb';
import { BreadcrumbPart } from 'types/Breadcrumb';
import { CMSPageData } from 'types/CMS';
import { Category } from 'types/Category';
import { Product } from 'types/Product';
import { getCategoriesArray } from './categoryUtil';

const getBreadcrumbPartsFromCategories = (category: Category): BreadcrumbPart[] => {
  if (!category) return [];

  return getCategoriesArray(category).reduce((acc: BreadcrumbPart[], curr) => {
    const { breadcrumbClickable, name, url } = curr;
    if (breadcrumbClickable && !!url) {
      return [...acc, { href: url, label: name ?? '' }];
    }
    return acc;
  }, []);
};

export const getBreadcrumbFromCategory = (category: Category): BreadcrumbProps => ({
  activeLabel: category.name,
  crumbs: category.superCategory ? getBreadcrumbPartsFromCategories(category.superCategory) : [],
});

export const getBreadcrumbFromProduct = (product: Product): BreadcrumbProps => {
  const category = product.categories?.[0];

  const crumbs = category ? getBreadcrumbPartsFromCategories(category) : undefined;

  return {
    activeLabel: product?.name,
    crumbs,
  };
};

export const getBreadcrumbFromCMSPage = (cmsPage: CMSPageData): BreadcrumbProps => {
  const { superCategory, title } = cmsPage;

  const crumbs = superCategory ? getBreadcrumbPartsFromCategories(superCategory) : undefined;

  return {
    activeLabel: title,
    crumbs,
  };
};
