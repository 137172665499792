import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import { log } from '../../utils/loggerUtil';

// NOTE: This component ONLY Catches run time errors
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    log(
      'componentDidCatch - components/ErrorBoundary/ErrorBoundary',
      `Component catched error`,
      { error, errorInfo }
    );
  }

  render() {
    const { message, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Typography type="h3">{message || 'Something went wrong.'}</Typography>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  message: null,
  children: null,
};
