import styled from 'styled-components';
import { variable, color, spacer } from 'theme';
import Typography from '../../../Typography/Typography';

export const StyledLabel = styled(Typography)`
  position: relative;
`;

export const StyledTimerLabel = styled(Typography)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

interface CountdownWrapperProps {
  backgroundColor?: string;
}

export const CountdownWrapper = styled.div<CountdownWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor || color('primary-300')};
  padding: ${spacer(100)} ${spacer(100)} ${spacer(250)} ${spacer(100)};
  border-radius: ${variable('border-radius')};
`;
