export const CONTENTFUL_TYPES = {
  ADVENT_CALENDAR: 'adventCalendar',
  BANNER: 'banner',
  BLOCK_DYNAMIC_PRODUCTS: 'blockDynamicProducts',
  BLOCK_IFRAME: 'blockIFrame',
  BLOCK_IMAGE: 'blockImage',
  BLOCK_NEWSLETTER: 'blockNewsletter',
  BLOCK_RELATED_ARTICLES: 'blockRelatedArticles',
  BLOCK_SOCIALS: 'blockSocials',
  BLOCK_SPONSORED_PRODUCTS: 'blockSponsoredProducts',
  BLOCK_TESTIMONIAL: 'blockTestimonial',
  BRAND: 'brand',
  CALL_TO_ACTION: 'callToAction',
  CHECKLIST: 'checklist',
  COMPONENT_GROUPING: 'componentGrouping',
  CONTAINER: 'container',
  CONTENT_BODY: 'contentBody',
  CONTENT_DEFAULT: 'contentDefault',
  CONTENT_HYBRID: 'contentHybrid',
  CONTENT_PROMO: 'contentPromo',
  CONTENT_SLOT: 'contentSlot',
  CONTENT_THEME: 'contentTheme',
  FEATURE_LIST: 'featureList',
  FEATURE_LIST_ITEM: 'featureItem',
  FEATURETTE: 'featurette',
  GROUPING_ANCHOR_LINKS: 'groupingAnchorLinks',
  GROUPING_CALL_TO_ACTIONS: 'groupingCallToActions',
  GROUPING_CATEGORIES: 'groupingCategories',
  GROUPING_CLICKABLE_CARDS: 'groupingClickableCards',
  GROUPING_FAQS: 'groupingFaqs',
  GROUPING_HIGHLIGHT: 'groupingHighlight',
  GROUPING_IMAGE_GRID: 'groupingImageGrid',
  GROUPING_LEADERBOARDS: 'groupingLeaderboards',
  GROUPING_PRODUCT_BANNER: 'groupingProductBanner',
  GROUPING_PRODUCT_CAROUSEL: 'groupingProductCarousel',
  GROUPING_PRODUCTS: 'groupingProducts',
  GROUPING_PRODUCTS_PROMO: 'groupingProductsPromo',
  GROUPING_SERVICES: 'groupingServices',
  GROUPING_SLIDER_TEXT: 'groupingSliderText',
  GROUPING_SPLIT: 'groupingSplit',
  GROUPING_TILE_CAROUSEL: 'groupingTileCarousel',
  GROUPING_TILE_CATEGORIES: 'groupingTileCategories',
  GROUPING_TILE_CONTENT: 'groupingTileContent',
  GROUPING_USP: 'groupingUsp',
  ICON: 'icon',
  LINK: 'link',
  PAGE: 'page',
  PAGE_CATEGORY: 'pageCategory',
  PAGE_CMS: 'pageCMS',
  PAGE_CONTENT: 'pageContent',
  PAGE_EXTERNAL: 'pageExternal',
  PAGE_HOME: 'pageHome',
  PAGE_HYBRID: 'pageHybrid',
  PAGE_PDP: 'pagePDP',
  PAGE_PLP: 'pagePLP',
  PRODUCT_CAROUSEL: 'productCarousel',
  PRODUCT_SELECTOR: 'productSelector',
  PRODUCT_TILE: 'productTile',
  RICH_TEXT: 'richText',
  RICH_TEXT_SIMPLE: 'richTextSimple',
  SLIDER_PROMOTED_BRANDS: 'sliderPromotedBrands',
  TAG: 'tag',
  TAG_SELECTOR: 'tagSelector',
  TILE_CONTENT: 'tileContent',
  TILE_PRODUCT: 'tileProduct',
  TILE_SERVICE: 'tileService',
  VIDEO: 'video',
} as const;

export type ContentfulType = typeof CONTENTFUL_TYPES[keyof typeof CONTENTFUL_TYPES];

export const HARD_CODED_COMPONENT_TYPES = {
  RECENT_PRODUCT_SLIDER: 'recentProductSlider',
} as const;

export type HardCodedComponentType = typeof HARD_CODED_COMPONENT_TYPES[keyof typeof HARD_CODED_COMPONENT_TYPES];

export const CALL_TO_ACTION_TYPES = {
  BUTTON: 'Button',
  LINK: 'Link',
} as const;
export type CallToActionType = typeof CALL_TO_ACTION_TYPES[keyof typeof CALL_TO_ACTION_TYPES];

export const CONTENTFUL_PAGES = [
  CONTENTFUL_TYPES.PAGE_CMS,
  CONTENTFUL_TYPES.PAGE_EXTERNAL,
  CONTENTFUL_TYPES.PAGE_PDP,
  CONTENTFUL_TYPES.PAGE_PLP,
  CONTENTFUL_TYPES.PAGE,
];

export const BANNER_VARIANT_TYPES = {
  SMALL: 'small',
  WIDE: 'wide',
} as const;
export type BannerVariantType = typeof BANNER_VARIANT_TYPES[keyof typeof BANNER_VARIANT_TYPES];

export const SEARCH_TYPES = {
  CATEGORY: 'CATEGORY',
  PRODUCT: 'PRODUCT',
  QUERY: 'QUERY',
};

export const GROUPING_PRODUCTS_TYPES = {
  CAROUSEL: 'Carousel',
  TILE: 'Tile',
};

export const CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS = {
  SIMPLE_VARIANT: 'simpleVariant',
  WIDER_CONTENT_VARIANT: 'widerContentVariant',
} as const;
export type TileContentSimpleVariant =
  typeof CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS[keyof typeof CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS];

export const CONTENTFUL_TILE_LIFESTYLE_VARIANTS = {
  COLORED_VARIANT: 'coloredVariant',
  SIMPLE_VARIANT: 'simpleVariant',
} as const;
export type TileLifeStyleVariant =
  typeof CONTENTFUL_TILE_LIFESTYLE_VARIANTS[keyof typeof CONTENTFUL_TILE_LIFESTYLE_VARIANTS];
