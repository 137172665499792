import { DELIMITERS } from 'constants/countdown';
import { ICountdown } from 'types/ContentfulTypes';
import { differenceInSeconds } from 'utils/dateUtil';
import Countdown from '../../../Countdown/Countdown';
import Flex from '../../../Flex/Flex';
import { ColoredBlock, StyledLabel } from './ContentfulBannerCountdown.styled';

interface ContentfulBannerCountdownProps {
  className?: string;
  content: ICountdown;
}

const ContentfulBannerCountdown = ({ className, content }: ContentfulBannerCountdownProps) => {
  const now = new Date();
  const timer = content?.fields?.timerEndTime && new Date(content?.fields?.timerEndTime);
  const visibleFrom = content?.fields?.visibleFrom && new Date(content?.fields?.visibleFrom);
  const shouldShowCountdown = visibleFrom && differenceInSeconds(visibleFrom, now) < 0;

  if (!shouldShowCountdown || !content?.fields) {
    return null;
  }

  const { color, design, label } = content.fields;
  const delimiter = design && (design.toUpperCase() as keyof typeof DELIMITERS);
  const selectedDelimiter = delimiter ? DELIMITERS[delimiter] : undefined;

  return (
    <Flex
      alignItems="center"
      className={className}
      flexDirection="column"
      justifyContent="center"
      paddingBottom={100}
      position="relative"
    >
      <ColoredBlock backgroundColor={color} />
      {label && <StyledLabel color="white">{label}</StyledLabel>}
      {timer && <Countdown date={timer.toDateString()} delimiter={selectedDelimiter} />}
    </Flex>
  );
};

export default ContentfulBannerCountdown;
