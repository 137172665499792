import { useQuery } from '@tanstack/react-query';
import { criteoKeys } from '.';
import { CriteoEventConfig, pushViewEvent } from './connector';

const useCriteoViewEvent = (config: CriteoEventConfig, enabled = true) =>
  useQuery({
    enabled,
    queryFn: () => pushViewEvent(config),
    queryKey: criteoKeys.sponsoredProducts(config.eventType),
  });

export { useCriteoViewEvent };
