import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import { breakpointUp, spacer, variable } from '../../../../theme/functions';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlockResponsive from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsive';
import { StyledContentfulBannerProps } from './ContentfulBanner';
import { FlexContent, Spacer } from 'constants/styling';

export interface ContentfulBannerContentProps {
  justifyContent: FlexContent;
  paddingBottom: Spacer;
}

export const ContentfulBannerWrapper = styled.div`
  width: 100%;
  left: 0;
  position: relative;
  min-height: 250px;
`;

export const StyledBannerImage = styled(ContentfulImageBlockResponsive)<StyledContentfulBannerProps>`
  ${({ showGradient }) =>
    showGradient &&
    css`
      height: 100%;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: ${variable('border-radius')};
        pointer-events: none;
        background: ${variable('image-gradient')};
      }
    `}
`;

export const ContentfulBannerContent = styled.div<ContentfulBannerContentProps>`
  display: flex;
  flex-direction: column;
  gap: ${spacer(100)};
  position: absolute;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : undefined)};
  height: 100%;
  padding-left: ${spacer(75)};
  padding-right: ${spacer(75)};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? spacer(paddingBottom) : undefined)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      width: 50%;
      padding-left: ${spacer(100)};
    `
  )};
`;

export const ContentfulBannerContentButton = styled(ContentfulCallToAction)`
  width: fit-content;
`;
