import { createClient } from 'contentful';

const contentfulClient = createClient({
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_KEY ?? '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
  removeUnresolved: true,
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? '',
});

const contentfulPreviewClient = createClient({
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_KEY ?? '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  host: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_HOST,
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? '',
});

const getContentfulClient = (isPreview = false) => {
  return isPreview ? contentfulPreviewClient : contentfulClient;
};

export default getContentfulClient;
