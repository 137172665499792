import { DELIMITERS } from 'constants/countdown';
import { ICountdown } from 'types/ContentfulTypes';
import { differenceInSeconds } from 'utils/dateUtil';
import Countdown from '../../../Countdown/Countdown';
import { CountdownWrapper, StyledLabel } from './ContentfulBlockCountdown.styled';

interface ContentfulBlockCountdownProps {
  className?: string;
  content: ICountdown;
}

const ContentfulBlockCountdown = ({ className, content }: ContentfulBlockCountdownProps) => {
  const now = new Date();
  const timer = content?.fields?.timerEndTime && new Date(content?.fields?.timerEndTime);
  const visibleFrom = content?.fields?.visibleFrom && new Date(content?.fields?.visibleFrom);
  const shouldShowCountdown = visibleFrom && differenceInSeconds(visibleFrom, now) < 0;

  if (!shouldShowCountdown || !content?.fields) {
    return null;
  }

  const { color, design, label } = content.fields;
  const delimiter = design && (design.toUpperCase() as keyof typeof DELIMITERS);
  const selectedDelimiter = delimiter ? DELIMITERS[delimiter] : undefined;

  return (
    <CountdownWrapper backgroundColor={color} className={className}>
      {label && <StyledLabel color="white">{label}</StyledLabel>}
      {timer && <Countdown date={timer.toDateString()} delimiter={selectedDelimiter} fontSize={300} />}
    </CountdownWrapper>
  );
};

export default ContentfulBlockCountdown;
