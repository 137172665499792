import { IBlockImage } from 'types/ContentfulTypes';
import { ImageSizes } from 'types/Image';
import { Image } from 'ui/Image/Image';
import { cn } from 'utils/cn';
import { mapResponsiveContentfulImages } from 'utils/contentfulUtil';

type Props = {
  className?: string;
  mediumImage?: IBlockImage;
  mobileImage?: IBlockImage;
  sizes: ImageSizes;
  wideImage?: IBlockImage;
};

const ContentfulImageBlockResponsiveNew = ({ className, mediumImage, mobileImage, sizes, wideImage }: Props) => {
  if (!(mobileImage || mediumImage || wideImage)) {
    return null;
  }

  const images = mapResponsiveContentfulImages(mobileImage, mediumImage, wideImage);

  return (
    <div className={cn('relative', className)}>
      {images?.map(
        ({ image, size }) =>
          image?.fields.image.fields.file?.url && (
            <Image
              key={`${image.fields.image.fields.title}-${size}`}
              className={cn(
                size === 'small' ? 'md:hidden' : '',
                size === 'medium' ? 'hidden md:block lg:hidden' : '',
                size === 'wide' ? 'hidden lg:block ' : '',
                'object-cover',
              )}
              src={image.fields.image.fields.file.url}
              alt={image.fields.image.fields.title}
              nextSizes={sizes}
              useNext
              fill
              priority
            />
          ),
      )}
    </div>
  );
};

export default ContentfulImageBlockResponsiveNew;
