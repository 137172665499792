import { ReactNode, memo } from 'react';
import isEqual from 'react-fast-compare';
import { IContentDefault, IContentPromo, IContentTheme, IContentThemeFields } from 'types/ContentfulTypes';
import ContentfulModelMapper from '../ContentfulModelMapper/ContentfulModelMapper';
import { StyledContentfulModelMapper } from './ContentfulTemplate.styled';

interface ContentfulTemplateProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalProps?: any;
  content: IContentDefault | IContentPromo | IContentTheme;
}

const ContentfulTemplate = ({ additionalProps, content }: ContentfulTemplateProps) => {
  const { fields } = content;

  if (!fields) {
    return null;
  }

  const { content: contentArray } = fields;

  const wrapWithTheme = (component: ReactNode, index: number, key: string) => {
    const { backgroundPattern } = fields as IContentThemeFields;
    const images = backgroundPattern?.map((pattern) => pattern?.fields?.image?.fields);

    if (images?.length) {
      return (
        <StyledContentfulModelMapper
          key={key}
          backgroundImageUrl={
            index % 6 === 1 ? images?.[0]?.file?.url : images?.[1]?.file?.url || images?.[0]?.file?.url
          }
          withThemedBackground={
            !!backgroundPattern?.length && index % 3 === 1 && index !== (contentArray?.length ?? 0) - 1
          }
        >
          {component}
        </StyledContentfulModelMapper>
      );
    }

    return component;
  };

  return (
    !!contentArray?.length && (
      <>
        {contentArray.map((entry, index) =>
          wrapWithTheme(
            <ContentfulModelMapper key={entry.sys.id} content={entry} additionalProps={additionalProps} />,
            index,
            entry.sys.id,
          ),
        )}
      </>
    )
  );
};

export default memo(ContentfulTemplate, isEqual);
