import styled, { css } from 'styled-components';
import { breakpointDown, breakpointUp } from 'theme';
import { BREAKPOINTS } from '../../constants/breakpoints';
import { color, spacer, variable } from '../../theme/functions';
import Box from '../Box/Box';
import Typography from '../Typography/Typography';

export const MobileShareOption = styled(Typography)`
  ${breakpointDown(
    BREAKPOINTS.MD,
    css`
      cursor: pointer;
    `,
  )};
`;

type ShareOptionsWrapperProps = {
  showOptions: boolean;
};

export const ShareOptionsWrapper = styled(Box)<ShareOptionsWrapperProps>`
  display: none;
  align-items: center;
  gap: ${spacer(100)};

  a {
    display: flex;
  }

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      align-items: center;
    `,
  )};

  ${({ showOptions }) =>
    showOptions &&
    css<ShareOptionsWrapperProps>`
      ${breakpointDown(
        BREAKPOINTS.MD,
        css`
          display: flex;
          position: absolute;
          top: calc(100% + ${spacer(100)});
          right: 0;
          background-color: ${color('white')};
          padding: ${spacer(50)};
          box-shadow: ${variable('box-shadow')}};
        `,
      )};
    `}
`;
