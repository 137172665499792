import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { FacetSearchResult } from 'types/Facet';
import { useMediaQuery } from 'usehooks-ts';
import { mapSigningsToCriteoFilters, mapSolrQueryToCriteoFilters, pipeConcat } from 'utils/criteoUtil';
import useRouter from '../useRouter';
import useCriteoRetailMedia, { CriteoConfig } from './useCriteoRetailMedia';

type UseCriteoRetailMediaForCategoryParams = {
  category?: string;
  searchResults?: FacetSearchResult;
  signings?: string;
};

export const useCriteoRetailMediaForCategory = (
  { category, searchResults, signings }: UseCriteoRetailMediaForCategoryParams,
  config: CriteoConfig = {},
) => {
  const { query } = useRouter();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const canSendEvents = !!category && !!searchResults;
  const { pagination, products: searchProducts = [] } = searchResults ?? {};
  const { currentPage = 0, pageSize: listSize = 0 } = pagination ?? {};
  const q = query?.q;

  const pageNumber = currentPage + 1;
  const solrQuery = Array.isArray(q) ? q[0] : q;
  const productCodesString = searchProducts?.reduce(
    (prev: string, entry) => (entry.code ? pipeConcat(prev, entry.code) : prev),
    '',
  );
  const mappedFilters = [mapSolrQueryToCriteoFilters(solrQuery), mapSigningsToCriteoFilters(signings)]
    .filter(Boolean)
    .join(',');

  return useCriteoRetailMedia(
    {
      category: category ?? '',
      eventType: CRITEO_EVENT_TYPES.VIEW_CATEGORY,
      filters: mappedFilters,
      isListView: false,
      isMobile,
      item: productCodesString,
      listSize,
      pageNumber,
    },
    { ...config, enabled: (config.enabled ?? true) && canSendEvents },
  );
};
