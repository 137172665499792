import { ReactNode } from 'react';
import { cn } from 'utils/cn';

type ContentfulLayoutProps = {
  children: ReactNode;
  className?: string;
};

const ContentfulLayout = ({ children, className }: ContentfulLayoutProps) => (
  <div className={cn('gap-13 flex flex-col py-3', className)}>{children}</div>
);
export default ContentfulLayout;
