import { AlgoliaProduct } from 'types/Algolia';
import { Facet, FacetDefinition } from 'types/Facet';
import { FormatMessage } from 'types/I18n';
import { Product } from 'types/Product';
import { Store } from 'types/Store';
import { FACET_CODE } from '../constants/search';

export const getProductCodesFromSearchResult = ({ products }: { products: Product[] }) =>
  products?.length ? products.map((product) => product.code)?.sort() : [];

export const getProductCodesFromHits = (hits: AlgoliaProduct[]) =>
  hits?.length > 0 ? hits.map((product) => product.code)?.sort() : [];

/**
 * Function which returns the favourite store facets
 * @param {Array} stores - the favourite store array
 * @param {Array} facets - the store facets array
 * @param {boolean=} anonymous - the anonymous flag
 * @return {object} the favourite store facets
 */
export const createFavouriteStoreFacets = (stores: Store[] = [], facets: Facet[] = [], anonymous = true) => {
  const favouriteStoreFacets: Facet[] = [];
  const storeFacets: Facet[] = [];

  if (stores && facets && facets.length > 0) {
    facets.forEach((facet) => {
      const facetCode = facet.code;
      if (!anonymous) {
        if (facetCode && stores?.find((store) => store.krefelId === facetCode)) {
          favouriteStoreFacets.push(facet);
        } else {
          storeFacets.push(facet);
        }
      } else if (facetCode && stores?.find((store) => store.krefelId === facetCode)) {
        favouriteStoreFacets.push(facet);
      } else {
        storeFacets.push(facet);
      }
    });
  }
  return { favouriteStoreFacets, storeFacets };
};

export const populateFacetsWithFavoriteStore = (
  facets: FacetDefinition[],
  favouriteStores: Store[],
  formatMessage: FormatMessage,
  anonymous: boolean,
) => {
  if (!facets?.length) {
    return null;
  }

  const storeFacets = createFavouriteStoreFacets(
    favouriteStores,
    facets.find((facet) => facet.code === FACET_CODE.STORES)?.values,
    anonymous,
  );

  if (!storeFacets?.favouriteStoreFacets?.length) {
    return facets;
  }

  const storeFacetIndex = facets?.findIndex((facet) => facet.code === FACET_CODE.STORES);
  const originalStoreFacet = facets[storeFacetIndex];

  const favouriteStoreFacet = {
    ...originalStoreFacet,
    code: `${originalStoreFacet.code}-favourites`,
    name: formatMessage({
      id: 'search_facets_filters_favourite_stores_subtitle',
    }),
    values: storeFacets.favouriteStoreFacets,
  };

  const populatedOriginalStoreFacet = {
    ...originalStoreFacet,
    values: storeFacets.storeFacets,
  };

  facets.splice(storeFacetIndex, 1, populatedOriginalStoreFacet);
  facets.splice(storeFacetIndex, 0, favouriteStoreFacet);

  return facets;
};
