import { BREAKPOINTS } from 'constants/breakpoints';
import { FlexContent, Spacer } from 'constants/styling';
import { useIntl } from 'react-intl';
import { IBanner } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { OptionalLink } from 'ui/Link/Link';
import { Text } from 'ui/Text/Text';
import Typography from '../../../Typography/Typography';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlockResponsiveNew from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsiveNew';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import {
  ContentfulBannerContent,
  ContentfulBannerContentButton,
  ContentfulBannerWrapper,
  StyledBannerImage,
} from './ContentfulBanner.styled';

export interface StyledContentfulBannerProps {
  showGradient?: boolean;
}

interface ContentfulBannerProps extends StyledContentfulBannerProps {
  className?: string;
  content: IBanner;
  hideCTA?: boolean;
  justifyContentBannerText?: FlexContent;
  paddingBottomBannerText?: Spacer;
  priority?: boolean;
  sizes?: ImageSizes;
  titleOverride?: string;
}

const ContentfulBanner = ({
  className,
  content,
  hideCTA,
  justifyContentBannerText = 'flex-end',
  paddingBottomBannerText = 200,
  priority,
  showGradient,
  sizes = {
    [BREAKPOINTS.XL]: 1176,
    [BREAKPOINTS.LG]: 992,
    [BREAKPOINTS.MD]: 768,
    [BREAKPOINTS.SM]: 576,
    [DEFAULT_SIZE_KEY]: 200,
  },
  titleOverride,
}: ContentfulBannerProps) => {
  const { formatMessage } = useIntl();

  if (!content?.fields) {
    return null;
  }

  const { button, color, copy, mediumImage, mobileImage, title, variant, wideImage } = content.fields;

  if (!(mobileImage || mediumImage || wideImage) && !color) {
    return null;
  }

  const hasContent = !!(title || copy || button);

  if (variant === 'new') {
    return (
      <OptionalLink className="relative flex flex-col gap-3">
        <ContentfulImageBlockResponsiveNew
          className="aspect-contentful-banner-mobile w-full overflow-hidden rounded-lg md:aspect-contentful-banner-tablet lg:aspect-contentful-banner-desktop"
          mobileImage={mobileImage}
          wideImage={wideImage}
          mediumImage={mediumImage}
          sizes={{
            default: 375,
            lg: 1024,
            md: 768,
          }}
        />

        {hasContent && (
          <div className="flex flex-col gap-2 rounded-[0.25rem] bg-white md:absolute md:left-6 md:top-1/2 md:w-[360px] md:-translate-y-1/2 md:px-6 md:py-4 lg:left-3 lg:w-[556px] lg:py-3 xl:left-6 xl:max-w-[490px]">
            <div className="flex flex-col">
              <Text type="h1">{title}</Text>
              <Text type="small" className="md:line-clamp-5 lg:line-clamp-3 xl:line-clamp-4">
                {copy}
              </Text>
            </div>

            {button && <ContentfulCallToAction className="mt-auto" content={button} />}
          </div>
        )}
      </OptionalLink>
    );
  }

  const body = (
    <ContentfulBannerWrapper className={className} id={content.sys?.id}>
      <StyledBannerImage
        mediumImage={mediumImage}
        mobileImage={mobileImage}
        priority={priority}
        showGradient={showGradient}
        sizes={sizes}
        wideImage={wideImage}
        className="contentful-banner-image"
      />

      {hasContent && (
        <ContentfulBannerContent
          justifyContent={justifyContentBannerText}
          paddingBottom={paddingBottomBannerText}
          className="contentful-banner-content"
        >
          {(titleOverride || title) && (
            <Typography
              className="contentful-banner-content-title"
              color="white"
              marginBottom={0}
              marginTop={0}
              type="h1"
            >
              {titleOverride || formatMessage({ id: title })}
            </Typography>
          )}
          {copy && (
            <Typography color="white" fontWeight="bold" className="contentful-banner-content-copy">
              {copy}
            </Typography>
          )}
          {!hideCTA && button && (
            <ContentfulBannerContentButton className="contentful-banner-content-button" content={button} />
          )}
        </ContentfulBannerContent>
      )}
    </ContentfulBannerWrapper>
  );

  if (hideCTA && button?.fields?.link) {
    return <ContentfulPageLink link={button?.fields?.link}>{body}</ContentfulPageLink>;
  }

  return body;
};

export default ContentfulBanner;
