import useRouter from 'hooks/useRouter';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnClickOutside } from 'usehooks-ts';
import config from '../../config';
import { ICON_NAMES, ICON_STYLES } from '../../constants/icon';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import { MobileShareOption, ShareOptionsWrapper } from './SocialSharing.styled';

type SocialSharingProps = {
  className?: string;
};

const SocialSharing = ({ className }: SocialSharingProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const shareOptionsWrapper = useRef<HTMLDivElement>(null);
  useOnClickOutside(shareOptionsWrapper, () => setShowOptions(false));

  const { formatMessage } = useIntl();
  const router = useRouter();
  const siteBaseUrl = config.seo.seoBaseUrl;
  const siteUrl = `${siteBaseUrl}${router.asPath}`;

  const options = [
    {
      icon: ICON_NAMES.TWITTER,
      iconStyle: ICON_STYLES.BRAND,
      link: `https://twitter.com/intent/tweet?url=${encodeURI(siteUrl)}`,
    },
    {
      icon: ICON_NAMES.FACEBOOK,
      iconStyle: ICON_STYLES.BRAND,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(siteUrl)}`,
    },
    {
      icon: ICON_NAMES.ENVELOPE,
      iconStyle: ICON_STYLES.REGULAR,
      link: `mailto:?body=${encodeURI(siteUrl)}`,
    },
    {
      icon: ICON_NAMES.WHATSAPP,
      iconStyle: ICON_STYLES.BRAND,
      link: `whatsapp://send?text=${encodeURI(siteUrl)}`,
    },
  ];

  return (
    <Flex alignItems="center" className={className} gap={150} position="relative">
      <div onClick={() => setShowOptions(true)} role="link" tabIndex={0}>
        <MobileShareOption>{formatMessage({ id: 'cms_share' })}</MobileShareOption>
      </div>

      <ShareOptionsWrapper ref={shareOptionsWrapper} showOptions={showOptions}>
        {options.map(({ icon, iconStyle, link }) => (
          <a key={link} href={link} onClick={() => setShowOptions(false)} rel="noopener noreferrer" target="_blank">
            <Icon color="black" iconStyling={iconStyle} name={icon} size={125} />
          </a>
        ))}
      </ShareOptionsWrapper>
    </Flex>
  );
};

export default SocialSharing;
