import { useFormatDate } from 'hooks/useFormatDate';
import { useIntl } from 'react-intl';
import { FontSize, FontWeight } from '../../constants/styling';
import { FormatDateOptions } from '../../utils/dateUtil';
import Typography from '../Typography/Typography';

interface TimestampProps {
  className?: string;
  date: string;
  dateOptions?: FormatDateOptions;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  label?: string;
  labelId?: string;
}

const Timestamp = ({
  className,
  date,
  dateOptions,
  fontSize = 88,
  fontWeight = 'bold',
  label,
  labelId,
}: TimestampProps) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatDate();

  if (!date) {
    return null;
  }

  const formattedDate = formatDate(date, { dateStyle: 'long', ...dateOptions });

  const getCopy = () => {
    if (labelId) {
      return formatMessage({ id: labelId }, { date: formattedDate });
    }

    if (label) {
      return `${label} ${formattedDate}`;
    }

    return formattedDate;
  };

  return (
    <Typography className={className} fontSize={fontSize} fontWeight={fontWeight}>
      {getCopy()}
    </Typography>
  );
};

export default Timestamp;
