import { ITag } from 'types/ContentfulTypes';
import { getContentfulContentForTags, getContentfulSlot } from 'utils/contentfulUtil';
import { logErrorResponse } from 'utils/loggerUtil';

export const fetchContentfulSlot = async (pathname: string, locale: string) => {
  try {
    const data = await getContentfulSlot({
      locale,
      pathname,
    });
    return data;
  } catch (error) {
    logErrorResponse('fetchContentfulSlot', error, `Error fetching contentful slot for pathname ${pathname}`);
    throw error;
  }
};

export const fetchContentForTags = async (tags: ITag[], locale: string) => {
  try {
    const data = await getContentfulContentForTags({
      locale,
      tags,
    });
    return data;
  } catch (error) {
    logErrorResponse('fetchContentForTags', error, `Error fetching contentful content for tags ${tags}`);
    throw error;
  }
};
